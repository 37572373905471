.cover-attribution > a {
  background-color:black;
  color:white;
  text-decoration:none;
  padding:4px 6px;
  line-height:1.2;
  display:inline-block;
  border-radius:3px;
}

.cover-attribution span {
  display:inline-block;
  padding:2px 3px;
}
